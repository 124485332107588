



































































































import ScholarRetentionAnalyticsComponent from '@/components/analyticsComponent/scholarAnalyticsComponent/scholarRetentionAnalyticsComponent/ScholarRetentionAnalyticsComponent';
export default ScholarRetentionAnalyticsComponent;
