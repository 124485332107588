import { Component, Vue, Watch } from 'vue-property-decorator';

import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import AnalyticsProgramDropdown from '../../commonComponents/analyticsProgramDropdown/AnalyticsProgramDropdown.vue';
import BarChart from '@/chartcomponents/barcomponent/BarComponent.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import ExportDropdown from '@/commoncomponents/exportdropdown/exportDropdown.vue';
import { IAdaWeekLinePayload } from '@/Model/model';
import LineChart from '@/chartcomponents/linecomponent/LineChart.vue';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import analyticsStore from '@/store/modules/analyticsStore';

@Component({
  components: {
    'analytics-program-dropdown': AnalyticsProgramDropdown,
    'multi-select-dropdown': MultiSelectDropdown,
    'dropdown': DropdownList,
    'line-chart': LineChart,
    'BarChart': BarChart,
    'export-to-csv': ExportDropdown,
    'bouncing-preloader': BouncingPreloaderComponent
  }
})


export default class ScholarRetentionAnalyticsComponent extends Vue {

  public masterTabsValue = APP_CONST.DAY_WEEK_TABS;
  public responseReceived: boolean = false;
  public retentionResponseReceived: boolean = false;
  public selectedTab: string = 'Week';
  public singleDropdownConfig = APP_CONST.USERS_SINGLE_DROPDOWN_CONFIG;
  public multiSiteDropdown = APP_CONST.ATTENDANCE_ADA_Groups_CONFIG;
  public yScaleLabel: string = '(%) Percent';
  public yScaleLabelBar: string = 'Percent (%)';
  public selectedGroups: any = [];
  public isNoData: boolean = false;
  public emptyStateStyle: any = {};
  public xAxisLabelString: string = '';
  public isDisplayXLabel: boolean = false;
  public barChartBackground: string = APP_CONST.STYLE.COLOR.BLUE_700;
  public barChartHoverBackground: string = APP_CONST.STYLE.COLOR.BLUE_700;
  public emptyBarStateStyle: any = {};
  public highestRole: number = JSON.parse(JSON.stringify(APP_UTILITIES.getCookie('highest_role')));
  public localConst: any = APP_CONST;
  public isMobileView: boolean = false;
  public isStaffNoGroupSelected: boolean = false;
  public isTabletView: boolean = false;
  public isChartMount: boolean = false;
  public isLineChartLoaderVisible: boolean = false;
  public isBarChartLoaderVisible: boolean = false;
  public oldSelectedData: any = '';

  @Watch('retentionLineData', { deep: true })
  updateSitesUpdatedData(val: any) {
    this.oldSelectedData = JSON.stringify(this.selectedSiteGroups);
  }

  get siteDropdown() {
    return analyticsStore.selectedprogramSitesRetention;
  }

  get selectedProgramData() {
    return analyticsStore.slectedProgram;
  }

  get isGroupsDisabled() {
    const isDisable = this.selectedSiteGroups.length
      ? false
      : true;
    return isDisable;
  }

  get selectedSiteGroups() {
    return analyticsStore.selectedSiteGroups;
  }

  get retentionLineData() {
    return analyticsStore.retentionLineData;
  }


  get retentionBarGraphData() {
    return analyticsStore.retentionBarData;
  }

  get retentionProgramPercentage() {
    return analyticsStore.retentionProgramPercentage;
  }

  get selectedSite() {
    return analyticsStore.adaselectedSite;
  }

  get retensionCsvDropdown() {
    return analyticsStore.retensionCsvDropdown;
  }

  get disableCsvButtonRetension() {
    return analyticsStore.disableDownloadCsvButtonRetension;
  }

  getProgramSites(selectedProgram: any) {
    if (selectedProgram && selectedProgram.programId) {
      analyticsStore.getProgramRetentionCompletionSites(selectedProgram.programId).then((response) => {
      });
    }

  }

  fetchChartData() {
    if (this.oldSelectedData && this.retentionLineData && this.oldSelectedData != JSON.stringify(this.selectedSiteGroups)) {
      this.prepareGraphData();
    }
  }

  selectGroup(group: any) {
    if (group.selectedValue != undefined) {
      analyticsStore.mutateSelectedGroups([]);
      analyticsStore.mutateSelectedGroups(group.dropdownList);
      analyticsStore.mutateRetentionSiteSession({ programId: this.selectedProgramData.programId, site: this.selectedSite, groups: group.dropdownList });
    }
  }

  updated() {
    this.isMobileView = APP_UTILITIES.isMobile();
  }

  isTablet() {
    this.isTabletView = APP_UTILITIES.tabletCheck();
  }

  beforeMount() {
    this.isBarChartLoaderVisible = true;
    this.isLineChartLoaderVisible = true;
    this.retentionResponseReceived = false;
    this.isMobileView = APP_UTILITIES.isMobile();
    this.isTabletView = APP_UTILITIES.tabletCheck();
    window.addEventListener('resize', APP_UTILITIES.debounce(this.isTablet));
    analyticsStore.mutateSelectedSite({ value: '', id: 0 });
    analyticsStore.retensionCsvEnableDisableItems();
    analyticsStore.mutateScholarNavigationSelection('retention');
    if (APP_UTILITIES.getFilteredColumns('retentionTabSelection')) {
      let storedTab: any = APP_UTILITIES.getFilteredColumns('retentionTabSelection');
      storedTab = JSON.parse(storedTab);
      this.selectedTab = storedTab.name;
    }
    else {
      this.selectedTab = 'Week';
    }
    if (APP_UTILITIES.getFilteredColumns('retentionselectedprogram')) {
      const program: any = APP_UTILITIES.getFilteredColumns('retentionselectedprogram');
      const selectedProgram = JSON.parse(program);
      analyticsStore.mutateSelectedProgramName(selectedProgram);
      analyticsStore.mutateSelectedGroups([]);
      this.getProgramSites(selectedProgram);
      if (this.highestRole <= APP_CONST.ROLE_TYPE_PROGRAM_ADMIN) {
        analyticsStore.getRetentionProgramData(selectedProgram.programId).then(() => {
          this.isBarChartLoaderVisible = false;
          this.retentionResponseReceived = true;
        });
      }
      analyticsStore.getPersistenceScholarTab({ selectedTab: 'retention', programId: selectedProgram.programId }).then(() => {
        this.prepareGraphData();
      });
    }
    else {
      analyticsStore.programNameSelection().then(() => {
        analyticsStore.mutateSelectedGroups([]);
        this.getProgramSites(this.selectedProgramData);
        if (this.highestRole <= APP_CONST.ROLE_TYPE_PROGRAM_ADMIN) {
          analyticsStore.getRetentionProgramData(this.selectedProgramData.programId).then(() => {
            this.isBarChartLoaderVisible = false;
            this.retentionResponseReceived = true;
          });
        }
        analyticsStore.getPersistenceScholarTab({ selectedTab: 'retention', programId: this.selectedProgramData.programId }).then(() => {
          this.prepareGraphData();
        });
      });
    }
  }


  prepareGraphData() {
    this.isLineChartLoaderVisible = true;
    const payload: IAdaWeekLinePayload = {
      programId: this.selectedProgramData && this.selectedProgramData.programId,
      siteId: this.selectedSite.id,
      groupIds: [],
      isDay: this.selectedTab == 'Day'
        ? true
        : false
    };
    if (this.selectedSiteGroups && this.selectedSiteGroups.length) {
      this.selectedSiteGroups.forEach((group: { id: any; checked: boolean }) => {
        if (group.checked) {
          payload.groupIds.push(group.id);
        }
      });
    }
    this.responseReceived = false;
    analyticsStore.getRetentionLineData(payload).then((response: any) => {
      this.responseReceived = true;
      this.isLineChartLoaderVisible = false;
      analyticsStore.mutateRetentionGraphData(response);
      this.scrollReset();
    });
  }


  scrollReset() {
    const elem: any = document.getElementById('line-chart-retention-week') as HTMLElement;
    elem && elem.scrollTo(0, 0);
  }


  get lineChartStyle() {
    let rententionWeekLength: number = 0;
    if (this.selectedTab == 'Week') {
      if (this.isMobileView) {
        rententionWeekLength = (this.retentionLineData && this.retentionLineData.labels && this.retentionLineData.labels.length)
          ? this.retentionLineData.labels.length * (this.retentionLineData.labels.length * 50 > 110
            ? 110
            : this.retentionLineData.labels.length * 50)
          : 0;
      }
      else {
        rententionWeekLength = (this.retentionLineData && this.retentionLineData.labels && this.retentionLineData.labels.length)
          ? this.retentionLineData.labels.length * (this.retentionLineData.labels.length * 10 > 110
            ? 110
            : this.retentionLineData.labels.length * 10)
          : 0;
      }
    }
    else {
      if (this.isMobileView) {
        rententionWeekLength = (this.retentionLineData && this.retentionLineData.labels && this.retentionLineData.labels.length)
          ? this.retentionLineData.labels.length * (this.retentionLineData.labels.length * 6 > 50
            ? 50
            : this.retentionLineData.labels.length * 6)
          : 0;
      }
      else {
        rententionWeekLength = (this.retentionLineData && this.retentionLineData.labels && this.retentionLineData.labels.length)
          ? this.retentionLineData.labels.length * (this.retentionLineData.labels.length * 5 > 50
            ? 50
            : this.retentionLineData.labels.length * 5)
          : 0;
      }
    }
    const styles: any = {
      width: rententionWeekLength > document.documentElement.clientWidth
        ? `${rententionWeekLength}px`
        : '100%',
      height: '400px',
      position: 'relative'
    };
    return styles;
  }

  get showLegends() {
    let checkedGroups: boolean = false;
    checkedGroups = this.retentionLineData && this.retentionLineData.datasets && this.retentionLineData.datasets[0] && this.retentionLineData.datasets[0].label
      ? true
      : false;
    return checkedGroups;
  }


  get emptyStateMessage() {
    let emptyStateMessage = APP_CONST.BLANK;
    this.emptyStateStyle = {};
    this.isStaffNoGroupSelected = false;
    if (this.highestRole == APP_CONST.ROLE_TYPE_STAFF && this.selectedSite && this.selectedSiteGroups) {
      if ((this.selectedSiteGroups && this.selectedSiteGroups.length && Object.keys(this.selectedSiteGroups).every((ele: any) => !this.selectedSiteGroups[ele].checked)) || (this.selectedSiteGroups && !this.selectedSiteGroups.length)) {
        this.isStaffNoGroupSelected = true;
      }
    }
    this.isNoData = this.retentionLineData && this.retentionLineData.datasets && Object.keys(this.retentionLineData.datasets).every((element: any) => this.retentionLineData.datasets[element].data && !this.retentionLineData.datasets[element].data.length);
    if (this.selectedSite.value == APP_CONST.BLANK || !this.siteDropdown.length || this.selectedSite.id == 0) {
      emptyStateMessage = APP_CONST.NO_SITE_SESSION;
      this.emptyStateStyle = {
        bottom: '120px',
        width: '291px'
      };
    }
    else if (this.isNoData && !this.isStaffNoGroupSelected) {
      emptyStateMessage = APP_CONST.AWAITING_ENROLLMENT;
      this.emptyStateStyle = {
        bottom: '125px',
        width: '291px'
      };
    }
    return emptyStateMessage;
  }


  selectProgramData(selectedProgram: any) {
    this.isBarChartLoaderVisible = true;
    this.retentionResponseReceived = false;
    APP_UTILITIES.setFilteredColumns('retentionselectedprogram', selectedProgram);
    analyticsStore.mutateSelectedSite({ value: '', id: 0 });
    analyticsStore.mutateSelectedGroups([]);
    this.getProgramSites(selectedProgram);
    if (this.highestRole <= APP_CONST.ROLE_TYPE_PROGRAM_ADMIN) {
      analyticsStore.getRetentionProgramData(selectedProgram.programId).then(() => {
        this.isBarChartLoaderVisible = false;
        this.retentionResponseReceived = true;
      });
    }
    this.scrollResetBarScroll();
    analyticsStore.getPersistenceScholarTab({ selectedTab: 'retention', programId: selectedProgram.programId }).then(() => {
      this.prepareGraphData();
    });
  }

  scrollResetBarScroll() {
    const elem: any = document.getElementById('barChartRetention') as HTMLElement;
    elem && elem.scrollTo(0, 0);
  }

  singleSiteSelection(site: any) {
    this.isLineChartLoaderVisible = true;
    this.selectedGroups = [];
    analyticsStore.mutateSelectedGroups([]);
    analyticsStore.mutateSelectedSite(site);
    const payload = {
      siteId: this.selectedSite.id,
      programId: this.selectedProgramData && this.selectedProgramData.programId

    };
    if (this.selectedSite.id == 0) {
      analyticsStore.mutateSelectedGroups([]);
    }
    const authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : '';
    let storeddata: any = APP_UTILITIES.getFilteredColumns(`${authKey}-retentionSiteSessionData`);
    storeddata = storeddata
      ? JSON.parse(storeddata)
      : '';
    analyticsStore.getProgramRetentionCompletionSites(this.selectedProgramData.programId).then((response) => {
      if (response && response.length == 1 && storeddata.hasOwnProperty([this.selectedProgramData.programId])) {
        this.isChartMount = true;
      }
      else {
        this.isChartMount = false;
      }
      if (!this.isChartMount) {
        analyticsStore.getGroupsBySiteid(payload).then((res: any) => {
          this.prepareGraphData();
          analyticsStore.retensionCsvEnableDisableItems();
          analyticsStore.mutateRetentionSiteSession({ programId: this.selectedProgramData.programId, site: site, groups: this.selectedSiteGroups });
        });

      }
    });
  }

  tabSwitch(tab: { name: string }) {
    this.selectedTab = tab.name;
    APP_UTILITIES.setFilteredColumns('retentionTabSelection', tab);
    this.prepareGraphData();
  }

  get barChartStyle() {
    let allApplicationLength: number = 0;
    if (this.isMobileView) {
      allApplicationLength = (this.retentionBarGraphData && this.retentionBarGraphData.length)
        ? this.retentionBarGraphData.length * (this.retentionBarGraphData.length * 50 > 110
          ? 110
          : this.retentionBarGraphData.length * 50)
        : 0;
    }
    else {
      allApplicationLength = (this.retentionBarGraphData && this.retentionBarGraphData.length)
        ? this.retentionBarGraphData.length * (this.retentionBarGraphData.length * 10 > 110
          ? 110
          : this.retentionBarGraphData.length * 10)
        : 0;
    }
    const styles: any = {
      width: allApplicationLength > document.documentElement.clientWidth
        ? `${allApplicationLength}px`
        : '100%',
      height: '400px',
      position: 'relative'
    };
    return styles;

  }


  get barEmtyStateMessage() {
    let emptyStateMessage = APP_CONST.BLANK;
    this.emptyBarStateStyle = {};
    if (this.siteDropdown.length == 0) {
      emptyStateMessage = APP_CONST.NO_SITE_SESSION_DATA;
      this.emptyBarStateStyle = {
        bottom: '150px',
        width: '291px',
      };
    }
    else if (this.retentionProgramPercentage == '--') {
      emptyStateMessage = APP_CONST.AWAITING_ENROLLMENT;
      this.emptyBarStateStyle = {
        bottom: '240px',
        width: '291px',
      };
    }
    return emptyStateMessage;

  }

  retensionCsvDownload(arg: any) {
    analyticsStore.downloadCsvRetension(arg);
  }



}
